<template>
  <div>
    <div>
        <b-form-input
            id="search"
            v-model="search"
            class="form-control-merge"
            name="Search"
            placeholder="············"
            @keyup.enter="getApiData('all', options, false)"
        />
    </div>
    <b-table
        ref="table"
        :key="tableKey"
        class="elevation-1"
         responsive
        :fields="headers"
        :items="items"
        :page.sync="page"
        :server-items-length="totalItems"
        :options.sync="options"
        @page-count="pageCount = $event"
        hide-default-footer
        multi-sort
        :loading="isLoading"
        :loading-text="$t('loading')"
    >


    </b-table>

    <b-row m-0 p-0>
      <b-col cols="12" class="text-center pt-2">
        <b-pagination v-model="page" :disabled='isLoading' :length="pageCount" :total-rows="totalItems" align="center" :total-visible="7"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BTable, BRow, BCol, BFormGroup, BFormInput, BPagination  } from 'bootstrap-vue'

export default {
  components: {
    BTable, BRow, BCol, BFormGroup, BFormInput, BPagination
  },
  name: "Table",
  props: ['headers', 'dataObject'],
  watch: {
    options: {
      deep: true,
      immediate: false,
      handler(newOptions) {
        if (this.data[this.type].fetched == true) {
          this.getApiData(this.type, newOptions, false)
        }
      }
    },
    '$route.params.type': {
      deep: true,
      immediate: true,
      handler(newType,) {
        if (newType == undefined) {
          this.type = 'all';
        } else {
          this.type = newType
          this.getApiData(this.type, this.options)
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      options: {},
      data: this.$props.dataObject,
      type: this.$route.params.type == null ? 'all' : this.$route.params.type,
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      filters: {},
      totalItems: 0,
      isDateMenuOpen: false,
      tableKey: 'init',
      items: [],
    }
  },
  methods: {
    getApiData(t, newOptions, checkTime = true) {
      if (checkTime && !this.getTimeDiff(this.data[t])) {
        this.items = this.data[t].items;
        return;
      }
      this.isLoading = true;
      this.$http.post(this.data[t].path, {
        'status': this.data[t].status,
        'options': newOptions,
        'filters': this.filters,
        'search': this.search
      }).then(response => {
        if (response.data.status == 200) {
          this.items = response.data.value.data
          this.data[t].items = response.data.value
          this.data[t].fetched = true
          this.data[t].last_fetch = new Date().getMinutes() + ':' + new Date().getSeconds()
          this.totalItems = response.data.value.total
        }
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
      })
    },
    getTimeDiff(dataType) {
      if (dataType.last_fetch == null) {
        return !dataType.fetched
      } else {
        let fetchedTime = dataType.last_fetch.split(':')
        if (fetchedTime[0] == new Date().getMinutes()) {
          if (parseFloat(fetchedTime[1]) + 15 < new Date().getSeconds())
            return true;
        } else {
          return true;
        }
        return false;
      }
    },
    // function  getSelectFilterItemsForField(field,headerItem){
    //   if(headerItem.fillDefault == true)
    //     return headerItem.filters // fills predefined filter from parent view(can be changed to api custom filter array)
    //   let uniqueFields = []
    //   this.dataObject[this.type].items.forEach((item)=>{
    //     uniqueFields.push(item[field])
    //   })
    //   return uniqueFields.filter(unique)//gets all unique elements from table
    // }
    getSelectFilterItemsForField(field, headerItem) {
      if (headerItem.dropdownData == false) {
        let uniqueFields = []
        this.$props.dataObject[this.type].items.forEach((item) => {
          uniqueFields.push(item[field])
        })
        return uniqueFields.filter([])//gets all unique elements from table
      } else {
        return headerItem.dropdownData // fills predefined filter from parent view(can be changed to api custom filter array)
      }
    },
    clearFilters() {
      this.options = {page: 1, itemsPerPage: 10}
      this.filters = {}
      this.search = ''
      // getApiData(type.value,options.value,false)
    },
  },
  computed: {
    unique(value, index, self) {
      return self.indexOf(value) === index
    },
    getSearchIcon() {
      // if (this.search == '') {
      //   return this.icons.mdiMagnify
      // } else {
      //   return this.icons.mdiFilterOffOutline
      // }
    },
    formTitle() {
      return 'New Item'
    },
  },
  created(){
    this.getApiData('all');
  }
}
</script>
<style scoped>
</style>
