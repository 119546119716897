<template>
  <div>
    <b-row m-0 p-0>
      <b-col cols="6">
        <h3>{{ $t("language_managment") }}</h3>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          type="submit"
          variant="success"
          role="button"
          @click="generateLanguages()"
        >
          {{ $t("generate_languages") }}
        </b-button>
      </b-col>
    </b-row>
    <validation-observer ref="languageVariableValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <b-card>
          <b-row m-0 p-0>
            <b-col cols="3">
              <b-form-group :label="$t('variable')" label-for="variable">
                <validation-provider
                  #default="{ errors }"
                  name="Variable"
                  rules="required"
                >
                  <b-form-input
                    id="variable"
                    v-model="lang.variable"
                    :state="errors.length > 0 ? false : null"
                    name="variable"
                    placeholder="variable-use-in-front"
                    :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :label="$t('english')" label-for="english">
                <validation-provider
                  #default="{ errors }"
                  name="English"
                  rules="required"
                >
                  <b-form-input
                    id="english"
                    v-model="lang.en"
                    :state="errors.length > 0 ? false : null"
                    name="english"
                    placeholder="en-text"
                    :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :label="$t('german')" label-for="german">
                <validation-provider
                  #default="{ errors }"
                  name="German"
                  rules="required"
                >
                  <b-form-input
                    id="german"
                    v-model="lang.de"
                    :state="errors.length > 0 ? false : null"
                    name="german"
                    placeholder="de-text"
                    :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3 pt-2">
              <b-button
                type="submit"
                variant="primary"
                block
                @click="addVariable"
              >
                {{ $t("add_variable") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
    <b-card v-if="!disableInputs">
      <b-row>
        <b-col cols="9"> </b-col>
        <b-col cols="3" class="right pb-1">
          <b-form-input
            id="search"
            v-model="search"
            class="form-control-merge"
            name="Search"
            :placeholder="$t('search')"
            @keyup.enter="getApiData()"
          />
        </b-col>
      </b-row>
      <b-table
        responsive
        ref="table"
        class="elevation-1"
        :fields="fields"
        :items="items"
        :page.sync="page"
        :server-items-length="totalItems"
        @page-count="pageCount = $event"
        hide-default-footer
        multi-sort
        :loading="isLoading"
        :loading-text="$t('loading')"
      >
        <template #cell(variable)="data">
          <span v-b-tooltip.hover.top="data.item.variable" >
            {{ data.item.variable.substring(0, 30) }}
            <span v-if="data.item.variable.length > 30"> ... </span>
          </span>
        </template>
        <template #cell(en)="data">
          <b-form-input v-model="data.item.en"></b-form-input>
        </template>
        <template #cell(de)="data">
          <b-form-input v-model="data.item.de"></b-form-input>
        </template>
        <template #cell(id)="data">
         <p style="width:65px;" class="badge badge-light-primary">{{data.value}}</p>
        </template>
        
        <template #cell(custom)="data">
          <b-button variant="outline-success" @click="updateVar(data.item)"
            >{{ $t("update") }}
          </b-button>
        </template>
      </b-table>
      <CustomLoader :show="isLoading" />
      <b-row m-0 p-0>
        <b-col cols="12" class="text-center pt-2">
          <b-pagination
            v-model="page"
            :disabled="isLoading"
            :total-rows="rows"
            @input="getApiData()"
            :per-page="itemsPerPage"
            align="center"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Table from "@/components/Common/Table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BTable,
  BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import CustomLoader from "@/components/Common/CustomLoader";
import i18n from "@/libs/i18n";

export default {
  components: {
    BTable,
    BRow,
    BPagination,
    CustomLoader,
    BCol,
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Table,
  },
  name: "Languages",
  directives: {
    Ripple,
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  data() {
    return {
      lang: {
        variable: null,
        en: null,
        de: null,
      },
      disableInputs: false,
      headers: [],
      languageTypes: {
        all: {
          path: "language/list",
          name: "all_languages",
          items: [],
          status: [],
          fetched: false,
          last_fetch: null,
        },
      },
      // validation rulesimport store from '@/store/index'
      required,
      options: null,
      fields: [
        { label: "ID", align: "start", key: "id" },
        { label: "Variable", align: "start", key: "variable" },
        { label: "EN", align: "start", key: "en" },
        { label: "DE", align: "start", key: "de" },
        { label: "", customSlot: true, key: "custom", class: "text-right" },
      ],
      items: [],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 12,
      status: [],
      isLoading: false,
      totalItems: 0,
    };
  },
  created() {
    this.getApiData();
  },
  methods: {
    updateVar(item) {
      let data = {
        variable: item.variable,
        en: item.en,
        de: item.de,
      };
      this.$http.post("/language/update/" + item.id, data).then((res) => {
        if (res) {
          this.$swal({
            position: "center",
            icon: "success",
            title: this.$t("updated_successfully"),
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    getApiData() {
      this.isLoading = true;
      this.items = [];
      this.$http
        .post("/language/list?page=" + this.page, {
          status: this.status,
          options: null,
          filters: this.filters,
          search: this.search,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    addVariable() {
      this.$refs.languageVariableValidation.validate().then((success) => {
        if (success) {
          this.disableInputs = true;
          this.$http.post("/language/store", this.lang).then((res) => {
            if (res.data.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t("language_variable_registered_successfully"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              this.getApiData();
              this.lang = {
                variable: null,
                en: null,
                de: null,
              };
              this.$refs.languageVariableValidation.reset();
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t("faild_register_the_variable"),
                  icon: "ErrorIcon",
                  variant: "error",
                },
              });
            }
            this.disableInputs = false;
          });
        }
      });
    },
    generateLanguages() {
      this.$http.post("/language/generate").then((res) => {
        window.location.href = res.data.value.file;
      });
    },
  },
};
</script>

<style scoped>
</style>
